import { useEffect, useState } from "react"
import { useParams } from 'react-router';
import { database } from "./Firebase";
import { ref, onValue} from "firebase/database";
import Bola from './Bola';
import './App.css';

function Player(props) {
    const { id } = useParams();
    const [init, setInit] = useState(null)
    const [Marcados, setMarcados] = useState([])
    const [Balls, setBalls] = useState(75)
    const [Cols, setCols] = useState(15)
    const [Rows, setRows] = useState(Math.ceil(Balls / Cols))
    const [FontSize, setFontSize] = useState(2)

    useEffect(() => {
        if(init) return
        setInit(true)
        onValue(ref(database, `info/${id}/Marcados`), (snapshot) => {
            console.log("setMarcados", snapshot.val());
            setMarcados(snapshot.val())
        })
        onValue(ref(database, `info/${id}/Balls`), (snapshot) => {
            console.log("setBalls", snapshot.val());
            setBalls(snapshot.val())
        })
        onValue(ref(database, `info/${id}/Cols`), (snapshot) => {
            console.log("setCols", snapshot.val());
            setCols(snapshot.val())
        })
    }, [id, init]) 

    useEffect(() => setRows(Math.ceil(Balls / Cols)), [Balls, Cols])

    return (
        <>
            <Marcador FontSize={FontSize} Balls={Balls} Marcados={Marcados} Cols={Cols} Rows={Rows} />
      <footer>
        <div>Bingo JFA © {new Date().getFullYear()}</div>
      </footer>
        </>
    )
}
function Marcador({ Balls, Marcados, Cols, Rows, FontSize }) {
    const styleWrapper = {
      top: "9.5vh",
      left: ".5vw",
      position: "absolute",
      border: "1px solid #fff",
      padding: ".5vh .5vw",
      borderRadius: "8px",
      backgroundColor: "#155",
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    }
    const styleTable = {
      display: "grid",
      gridTemplateColumns: `repeat(${Cols}, 1fr)`,
      gridTemplateRows: `repeat(${Rows}, 1fr)`,
      overflow: 'hidden',
    }
    return (
      <div style={styleWrapper} >
        <div style={styleTable} >
          {[...Array(Balls).keys()].map(i => <Bola key={i}
            number={i + 1}
            color={Marcados?.find((_) => _ === i + 1 ) ? "red" : "white"}
            ballsize={FontSize*21}
            fontSize={FontSize}
            style={{
              width: `calc(98vw / ${Cols})`,
              height: `calc(80vh / ${Rows})`,
            }} />)}
        </div>
      </div>
    )
  }
  
export default Player