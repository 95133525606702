import React, { useState, createContext, useMemo, useContext, useEffect } from "react"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import { useLoginContext } from "./Login"
import { getDatabase, ref, onValue} from "firebase/database"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './Payment.css';
const PaymentContext = createContext()

function Payment({ children }) {
    const {user, logout} = useLoginContext()
    const [pagovalido, setPV] = useState(true)
    const [lastPayment, setLastPayment] = useState(false)
    // useEffect(() => {
    //     const db = getDatabase()
    //     const payments = ref(db, 'payments')
    //     onValue(payments, (snapshot) => {
    //       const data = snapshot.val()
    //     //   console.log(data)
    //       if (!data) return
    //       const ownpayments = Object.keys(data).map( key => data[key]).filter(({event_type, resource}) => {
    //         // console.log(event_type, resource)
    //         if (!resource) return false
    //         const {purchase_units} = resource
    //         if (!purchase_units || !purchase_units[0]) return false
    //         const {reference_id} = purchase_units[0]
    //         return reference_id && event_type === "CHECKOUT.ORDER.APPROVED" && reference_id ===  user.email
    //       })
    //       ownpayments.sort(({create_time:a}, {create_time:b}) =>  (+new Date(b)) - (+new Date(a)))
    //       setLastPayment(ownpayments && ownpayments.length && ownpayments[0].create_time)
    //     })
    // }, [user])
    // useEffect(() => {
    //     setPV((+(new Date(lastPayment).addDays(30))) > (+ new Date()))
    // }, [lastPayment])

    const contextValue = useMemo(() => ({lastPayment}), [lastPayment])
    return <PaymentContext.Provider value={contextValue} >
        {pagovalido ? children :<div style={{display: "flex", height: "calc(100vh - 100px)"}}>
            <div style={{
                margin: "auto",
                borderRadius: "4px",
                padding: "10px 10px 0px",
                background: "#ddd",
                boxShadow: "0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%)",
            }}>
                <PayButton /> 
            </div>
        </div>}
      <footer>
        <div>{user?.email} | 
            {!lastPayment ? null :  new Intl.DateTimeFormat('es-ES').format(new Date(lastPayment).addDays(30)) + " | "}
            <button className="menuBtn" onClick={() => logout()}> Cerrar Session </button>
        </div>
        <div>Bingo JFA © {new Date().getFullYear()}</div>
      </footer>
    </PaymentContext.Provider>
}

function PayButton() {
    const {user} = useLoginContext()
    // const clientID = "AZcziDETyIbcMfeh3wKCTt8x3KdLoP-Gw3rjvb-H6y5iVNHKUukGhNYgY7m2O3B9t6qStS14Z1oSQaQl" // sandbox
    const clientID = "AaC-j0p0JGYeroiSRC3HB1V6dWgZHh7YiUklj8xg-D8lk1w9Qo8Kx2zk6l2Dwfh93XxW4pZbBEZsMjgI"
    return (
        <PayPalScriptProvider options={{ "client-id": clientID }}>
            <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        custom_id: user.email,
                        reference_id:  user.email,
                        purchase_units: [
                            {
                                amount: {
                                    currency_code: "USD",
                                    value: "48.75",
                                    breakdown: {
                                      item_total: {  /* Required when including the `items` array */
                                        currency_code: "USD",
                                        value: "48.75"
                                      }
                                    }
                                },
                                custom_id: user.email,
                                reference_id:  user.email,
                                items: [
                                  {
                                    name: "Bingo JFA - Subscripcion 30 dias",
                                    description: "Bingo JFA - Subscripcion 30 dias", 
                                    unit_amount: {
                                      currency_code: "USD",
                                      value: "48.75",
                                    },
                                    quantity: "1"
                                  },
                                ]
                            },
                        ],
                    })
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                        const name = details.payer.name.given_name
                        console.log(`Transaction completed by ${name}`)
                        confirmAlert({
                            title: 'Pago finalizado',
                            message: `Muchas gracias ${name}! Tu pago parece correcto, en cuanto Paypal nos lo confirme podras usar Bingo JFA por 30 días.`,
                            buttons: [{
                                label: 'Ok',
                                onClick: () => null
                            }]
                        })
                    })
                }}
            />
        </PayPalScriptProvider>
    )
}

export function usePaymentContext() {
    return useContext(PaymentContext)
}

export default Payment