import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getAuth } from 'firebase/auth'
import { getDatabase, ref, set, child, get } from "firebase/database"
import * as firebaseAuth from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCeVoM4bCu1k2dioebWBvJ3-wRQniFDewI",
  authDomain: "bingo-jfa.firebaseapp.com",
  projectId: "bingo-jfa",
  storageBucket: "bingo-jfa.appspot.com",
  messagingSenderId: "1034046894436",
  appId: "1:1034046894436:web:592005c1c932232bfc8a71",
  databaseURL: "https://bingo-jfa-default-rtdb.firebaseio.com",
  measurementId: "G-VNNTRC0GZE"
}

// Initialize Firebase
let firebaseApp
try {
  firebaseApp = initializeApp(firebaseConfig)
} catch (e) {
  console.log(e)
}

export const analytics = getAnalytics(firebaseApp)
export const auth = getAuth(firebaseApp)

firebaseAuth.setPersistence(auth, firebaseAuth.browserLocalPersistence)
firebaseAuth.setPersistence(auth, firebaseAuth.indexedDBLocalPersistence)
firebaseAuth.setPersistence(auth, firebaseAuth.inMemoryPersistence)
firebaseAuth.setPersistence(auth, firebaseAuth.browserSessionPersistence)

export const database = getDatabase(firebaseApp)
export function updateFirebaseInfo(userId, key, data) {
  set(ref(database, `info/${userId}/${key}`), data)
}
export async function getFirebaseInfo(userId, key) {
  const dbRef = ref(database)
  const snapshot = await get(child(dbRef, `info/${userId}/${key}`))
  if (!snapshot) return undefined
  if (snapshot.exists()) {
    return snapshot.val()
  } else {
    return null
  }
}

export default firebaseApp