
// import { get, set } from "idb-keyval";
import React, { createContext, useMemo, useContext, useEffect, useCallback, useState } from "react"
import './Login.css';
import {auth} from "./Firebase";
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged  } from "firebase/auth";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
const LoginContext = createContext()

function Login({ children }) {
    const [user, setUser] = useState(null)
    const [init, setInit] = useState(null)
    const [loading, setLoading] = useState(null)
    // const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);
    const signInWithGoogle = useCallback(() => {
        setLoading(true)
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
          .then((result) => {
            setUser(result?.user)
            setLoading(false)
            // console.log("signInWithPopup", result)
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // ...
          }).catch((error) => {
            setUser(null)
            setLoading(false)
            console.log("Error signInWithPopup", error)
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
          })

    }, [user, setUser, loading, setLoading])
    
    // useEffect(() => {
    //     // console.log("user", user)
    // }, [user])
    
    useEffect(() => {
        if (init) return 
        setInit(true)
        onAuthStateChanged(auth, setUser)
    }, [init, setInit, setUser])
    
    const logout = () => confirmAlert({
      title: 'SALIR',
      message: 'Seguro que quieres cerrar sesion?',
      buttons: [
        {
          label: 'Si',
          onClick: () => auth.signOut()
        },
        {
          label: 'No',
          onClick: () => null
        }
      ]
    }) 
    const contextValue = useMemo(() => ({ user, logout }), [user, logout])
    return <LoginContext.Provider value={contextValue} >
        {loading ? "Cargando..." :
            (user ? children : <LoginForm signInWithGoogle={signInWithGoogle} />)}
    </LoginContext.Provider>
}

function LoginForm({ signInWithGoogle }) {
    const loginFn = useCallback(() => {
        signInWithGoogle()
    }, [signInWithGoogle])
    return <div className="Modal">
        <div className="logo">
            <img src="logo192.png" width="64px" height="64px" />
            <span> Bingo JFA </span>
        </div>
        <form onSubmit={e => e.preventDefault()}>
            <button onClick={() => loginFn()}>Entrar</button>
        </form>
    </div>
}

export function useLoginContext() {
    return useContext(LoginContext);
}

export default Login;