function Bola(props) {
    const { number, color, ballsize, fontSize, animate } = props
    return (
        <svg version="1.1" {...props}>
            <g >
                <circle
                    fill={`url(#degrad_${color})`}
                    r={`${ballsize}%`}
                    cx="50%" cy="50%"
                >
                    {animate && (
                        <animate 
                            attributeName="r" 
                            begin="0s" dur="2s" 
                            repeatCount="indefinite" 
                            values={`${ballsize}%;${ballsize*0.95}%;${ballsize}%`} />
                    )}
                    {!animate && (
                        <animate 
                            attributeName="r" 
                            begin={`${Math.random() * 75 + 1}s`} 
                            dur={`${Math.random() * 5 + 1}s`} 
                            repeatCount="indefinite" 
                            values={`${[...Array(Math.floor(Math.random() * 10) + 1).keys()].map(() => `${ballsize}%`).join(";")};${ballsize}%;${ballsize}%;${ballsize * 1.02}%;${ballsize}%`} />
                    )}
                </circle>
                <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    stroke={color}
                    strokeWidth={`${fontSize / 12}rem`}
                    dy=".3em"
                    filter="invert(100%)"
                    fill="black"
                    fontFamily={'"Noto Sans", sans-serif'}
                    fontSize={`${fontSize}rem`}
                > {number < 10 ? "0" : null}{number} </text>
            </g>
            {color === "red" && <radialGradient id="degrad_red" cx="20%" cy="20%" r="100%" fx="30%" fy="30%">
                <stop stopColor="#ccc" offset="0" />
                <stop stopColor="red" offset="25%" />
                <stop stopColor="rgb(192,0,0)" offset="50%" />
                <stop stopColor="rgb(127,0,0)" offset="70%" />
                <stop stopColor="rgb(64,0,0)" offset="85%" />
                <stop stopColor="rgb(0,0,0)" offset="100%" />
            </radialGradient>}
            {color === "white" && <radialGradient id="degrad_white" cx="20%" cy="20%" r="100%" fx="30%" fy="30%">
                <stop stopColor="#ccc" offset="0" />
                <stop stopColor="rgb(192,192,192)" offset="50%" />
                <stop stopColor="rgb(127,127,127)" offset="70%" />
                <stop stopColor="rgb(64,64,64)" offset="85%" />
                <stop stopColor="rgb(0,0,0)" offset="100%" />
            </radialGradient>}
            {color === "blue" && <radialGradient id="degrad_blue" cx="20%" cy="20%" r="100%" fx="30%" fy="30%">
                <stop stopColor="#ccc" offset="0" />
                <stop stopColor="blue" offset="25%" />
                <stop stopColor="rgb(0,0,192)" offset="50%" />
                <stop stopColor="rgb(0,0,127)" offset="70%" />
                <stop stopColor="rgb(0,0,64)" offset="85%" />
                <stop stopColor="rgb(0,0,0)" offset="100%" />
            </radialGradient>}
        </svg>
    )
}
export default Bola;
