import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './Login';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Payment from './Payment';
import Player from './Player';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

const GA_TRACKING_ID = "G-908G64LME7";
const GAScript = () => <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />

const root = ReactDOM.createRoot(document.getElementById('root'));
const WrapApp = () => (
  <Login>
    <Payment>
      <App />
    </Payment>
  </Login>
)
root.render(
  <React.Fragment>
    <Router>
      <GAScript />
      <Routes>
        <Route path="/player/:id" element={<Player />} />
        <Route path="/" element={<WrapApp />} />
        <Route path="*" element={<Navigate to ="/" />}/>
      </Routes>
    </Router>
  </React.Fragment>
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


var gtag = undefined;
const gaEvent = ({
  name,
  category,
  label,
  value
}) => {
  if (typeof (gtag) === undefined) {
    window.dataLayer = window.dataLayer || [];
    gtag = function () {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', `${GA_TRACKING_ID}`, { 'debug_mode': true });
  }
  if (typeof (gtag) === "function") {
    gtag('event', name ? name : 'debug', {
      event_category: category ? category : 'Bingo',
      event_label: label ? label : 'bingo',
      value: typeof (value) === 'string' ? value : JSON.stringify(value),
    });
  }
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals(gaEvent);
