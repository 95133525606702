import React, { useState, useEffect, useCallback, useRef } from "react"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { Squash as Hamburger } from 'hamburger-react'
import { Fade, Bounce } from 'react-reveal'
import Bola from './Bola'
import { useLoginContext } from './Login'
import './App.css'
import { updateFirebaseInfo, getFirebaseInfo } from "./Firebase"
import QRCode from 'qrcode.react'

function App() {
  const [Balls, setBalls] = useState(75)
  const [Marcados, setMarcados] = useState([])
  const [Cols, setCols] = useState(15)
  const [FontSize, setFontSize] = useState(2)
  const [Rows, setRows] = useState(Math.ceil(Balls / Cols))
  const [sorteando, setSorteando] = useState(false)
  const [tempNumero, setTempNumero] = useState(0)
  const [sorteoVisible, setsorteoVisible] = useState(false)
  const [init, setInit] = useState(null)
  const componentMounted = useRef(true)
  const {user} = useLoginContext()

  const marcarNumero = useCallback((numero) => {
    const newM = Marcados// .map((m, i) => i + 1 === numero || m)
    if(newM.indexOf(numero) === -1)   newM.push(numero)
    setMarcados(newM)
    updateFirebaseInfo(user?.uid, "Marcados", newM)
  }, [Marcados, setMarcados, user])

  const resetMarcador = useCallback((preguntar = false) => {
    const R = () => {
      const newM = []
      setMarcados(newM)
      updateFirebaseInfo(user?.uid, "Marcados", newM)
    }
    if (!preguntar) return R()
    confirmAlert({
      title: 'CONFIRMAR',
      message: 'Confirma reiniciar el marcador',
      buttons: [
        {
          label: 'Si',
          onClick: R
        },
        {
          label: 'No',
          onClick: () => null
        }
      ]
    })
  }, [Balls,  Marcados, setMarcados, user])

  const sortearNumero = useCallback(async () => {
    if (sorteando) return console.log('Ya esta sorteando')
    if (sorteoVisible) {
      setsorteoVisible(false)
      return console.log('Ocultar sorteo')
    }
    if (!Marcados?.length >= Balls) return console.log('No hay mas numeros para sortear')
    setSorteando(true)
    let numero
    while (true) {
      numero = Math.ceil(Math.random() * Balls)
      // eslint-disable-next-line
      if (!Marcados.find((_) => _ === numero)) break
    }
    setsorteoVisible(true)
    for (let i = 0; i < 120; i++) {
      setTempNumero(Math.ceil(Math.random() * Balls))
      await new Promise(resolve => setTimeout(resolve, (Math.random() * 10) + (i / 3)))
    }
    setTempNumero(numero)
    console.log('Numero: ', numero)
    marcarNumero(numero)
    setSorteando(false)
  }, [Balls, sorteando, Marcados, sorteoVisible, marcarNumero, setSorteando, setTempNumero, setsorteoVisible])

  // const keyUpHandle = useCallback(event => {
  //   if (event.code === 'Space') sortearNumero()
  // }, [sortearNumero])

  const actualizarConfigs = ({ balls, cols, fontsize }) => {
    setBalls(Number(balls))
    setCols(Number(cols))
    setFontSize(Number(fontsize))
    setRows(Math.ceil(balls / cols))
    saveConfigs({ B: Balls, C: Cols, R: Rows })
    resetMarcador()
  }

  const saveConfigs = ({ B, C, R }) => {
    updateFirebaseInfo(user?.uid, "Balls", B)
    updateFirebaseInfo(user?.uid, "Cols", C)
    updateFirebaseInfo(user?.uid, "Rows", R)
  }

  useEffect(() => {
    if (init) return 
    setInit(true)
    const initvalues = async () => {
      const _Balls = await getFirebaseInfo(user?.uid, "Balls")
      const _Cols = await getFirebaseInfo(user?.uid, "Cols")
      const _Rows = await getFirebaseInfo(user?.uid, "Rows")
      const _Marcados = await getFirebaseInfo(user?.uid, "Marcados")
      console.log("initvalues", _Balls, _Cols, _Rows, _Marcados)
      setBalls(val => _Balls || val)
      setCols(val => _Cols || val)
      setRows(val => _Rows || val)
      setMarcados(val => _Marcados || val)
      if (!_Marcados || !_Marcados.length) resetMarcador()
    }
    initvalues()
    // document.addEventListener('keyup', keyUpHandle)
    return () => {
      // document.removeEventListener('keyup', keyUpHandle, true)
      componentMounted.current = false
    }
    // }, [Balls, Cols, Rows, Marcados])
  }, [componentMounted, Marcados, setBalls, setCols, setRows, setMarcados, resetMarcador, init, setInit])

  return (
    <div className="App">
      <NavBar
        sorteando={sorteoVisible}
        Balls={Balls}
        Cols={Cols}
        Marcados={Marcados}
        FontSize={FontSize}
        actualizarConfigs={actualizarConfigs}
        sortearNumero={sortearNumero}
        resetMarcador={() => resetMarcador(true)} />
      <Marcador FontSize={FontSize} Balls={Balls} Marcados={Marcados} Cols={Cols} Rows={Rows} />
      <SorteoBall FontSize={FontSize} number={tempNumero} visible={sorteoVisible} />
    </div>
  )
}

function useInput(props) {
  const [value, setValue] = useState(props.initval)
  const input = <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  return [value, input]
}

function NavBar({ sortearNumero, Marcados, resetMarcador, sorteando, actualizarConfigs, 
  Balls, Cols, FontSize  }) {
  const [isOpen, setOpen] = useState(false)
  const [showConfig, toggleConfig] = useState(false)
  const [balls, ballsInput] = useInput({ initval: Balls, type: "number", name: "bals", tabIndex: "1", id: "bals" })
  const [cols, colsInput] = useInput({ initval: Cols, type: "number", name: "cols", tabIndex: "2", id: "cols" })
  const [fontsize, fontsizeInput] = useInput({ initval: FontSize, type: "number", name: "fontsize", tabIndex: "3", id: "fontsize" })
  const { user } = useLoginContext()
  
  const download = () => {
    const canvas = document.querySelector('.HpQrcode > canvas')
    var link = document.getElementById('linkQRCode');
    link.setAttribute('download', `QR-${user.uid}.png`);
    link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
    link.click();
  }

  return (
    <>
      <nav className="navBar">
        <Hamburger toggled={isOpen} toggle={setOpen} color="#eee" size={20} rounded /> 
        <Fade left cascade collapse duration={100} when={isOpen}>
          <div style={{ display: "flex", zIndex: 1001, }}>
            <button className="menuBtn" onClick={sortearNumero}> {sorteando ? "Ocultar" : "Sortear"} </button>
            {Marcados && Marcados.length && <button className="menuBtn" onClick={resetMarcador}> Reiniciar </button> || null}
            <button className="menuBtn" onClick={() => toggleConfig(!showConfig)}> Configurar </button>
            <button className="menuBtn" onClick={() => download()}> Obtener QR </button>
            {/* <button className="menuBtn" onClick={() => logout()}> Cerrar Session </button> */}
          </div>
        </Fade>
      </nav>
      
      <div style={{display: "none"}} className="HpQrcode"> 
      <a id="linkQRCode"></a>
        <QRCode
          value={`https://bingo.jfaramburu.com/player/${user.uid}`}
          size={512}
          level={'L'}
        />
      </div>
      <div style={{
        display: showConfig ? "block" : "none",
        width: "100vw",
        position: 'absolute',
        background: "#0005",
        height: "100vh",
        zIndex: 1000,
        top: 0,
        left: 0

      }}>
        <Fade top collapse duration={600} when={showConfig}>
          <div style={{
            position: 'absolute',
            display: 'flex',
            width: "250px",
            background: "white",
            borderRadius: "8px",
            top: "calc(50vh - 150px)",
            left: "calc(50vw - 125px)",
            boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
          }} >
            <div style={{ margin: "auto", padding: "15px" }} >
              <fieldset>
                <legend>Configuración</legend>
                <label style={{color: "#055"}}>N° Bolas: {ballsInput}</label>
                <label style={{color: "#055"}}>Columnas: {colsInput} </label>
                <label style={{color: "#055"}}>Tamaño: {fontsizeInput} </label>
                <button className="menuBtn" onClick={() => toggleConfig(false)}>Cancelar</button>
                <button className="menuBtn" onClick={() => actualizarConfigs({ balls, cols, fontsize })}>Aplicar</button>
              </fieldset>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

function Marcador({ Balls, Marcados, Cols, Rows, FontSize }) {
  const styleWrapper = {
    top: "9.5vh",
    left: ".5vw",
    position: "absolute",
    border: "1px solid #fff",
    padding: ".5vh .5vw",
    borderRadius: "8px",
    backgroundColor: "#155",
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
  }
  const styleTable = {
    display: "grid",
    gridTemplateColumns: `repeat(${Cols}, 1fr)`,
    gridTemplateRows: `repeat(${Rows}, 1fr)`,
    overflow: 'hidden',
  }
  return (
    <div style={styleWrapper} >
      <div style={styleTable} >
        {[...Array(Balls).keys()].map(i => <Bola key={i}
          number={i + 1}
          color={Marcados?.find((_) => _ === i + 1 ) ? "red" : "white"}
          ballsize={FontSize*21}
          fontSize={FontSize}
          style={{
            width: `calc(98vw / ${Cols})`,
            height: `calc(80vh / ${Rows})`,
          }} />)}
      </div>
    </div>
  )
}

function SorteoBall({ number, visible, FontSize }) {
  return <Bounce top opposite when={visible} style={{ position: 'absolute' }} >
    <Bola
      number={number}
      ballsize={15 * FontSize}
      fontSize={7 * FontSize}
      color="blue"
      animate="true"
      style={{
        width: `80vw`,
        height: `80vh`,
        position: 'absolute',
        top: "50vh",
        left: "50vw",
        transform: "translate(-50%, -50%)",
      }} />
  </Bounce>
}

export default App
